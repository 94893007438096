import { graphql } from "gatsby"
import React from "react"

import TextImageAndStatsComponent from "./TextImageAndStats"

export const fragment = graphql`
  fragment TextImageAndStatsFragment on WpPage_Flexlayouts_FlexibleLayouts_TextImageAndStats {
    image {
      altText
      sourceUrl
      localFile {
        childImageSharp {
          gatsbyImageData(
            formats: [AUTO, WEBP, AVIF]
            height: 1400
            placeholder: NONE
          )
        }
      }
    }
    sectionHeading
    text
    links {
      link {
        url
        title
        target
      }
    }
    stats {
      stat
      label
    }
    config {
      padding {
        top {
          topMobile
          topTablet
          topDesktop
        }
        bottom {
          bottomMobile
          bottomTablet
          bottomDesktop
        }
      }
    }
  }
`

const ACFTextImageAndStats = ({
  googleReviewData,
  image,
  sectionHeading,
  text,
  links,
  stats,
  config,
}) => {
  return (
    <TextImageAndStatsComponent
      googleReviewData={googleReviewData}
      image={image}
      sectionHeading={sectionHeading}
      text={text}
      links={links}
      stats={stats}
      config={config}
    />
  )
}

export default ACFTextImageAndStats
