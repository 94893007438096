// extracted by mini-css-extract-plugin
export var bgGrey100 = "OrgStats-module--bg-grey-100--30260";
export var bgGrey150 = "OrgStats-module--bg-grey-150--df233";
export var bgGrey200 = "OrgStats-module--bg-grey-200--1c555";
export var bgGrey300 = "OrgStats-module--bg-grey-300--b63fd";
export var bgGrey400 = "OrgStats-module--bg-grey-400--25923";
export var bgGrey500 = "OrgStats-module--bg-grey-500--84635";
export var bgGrey600 = "OrgStats-module--bg-grey-600--1fd8b";
export var bgGrey700 = "OrgStats-module--bg-grey-700--9f829";
export var bgGrey800 = "OrgStats-module--bg-grey-800--5778f";
export var bgGrey900 = "OrgStats-module--bg-grey-900--48d05";
export var ratingBoxCls = "OrgStats-module--ratingBoxCls--1ecab";
export var starsCls = "OrgStats-module--starsCls--75e1c";
export var statBoxCls = "OrgStats-module--statBoxCls--1b0f0";
export var statCls = "OrgStats-module--statCls--89a32";
export var statLableCls = "OrgStats-module--statLableCls--8d6ce";
export var statsWrapperCls = "OrgStats-module--statsWrapperCls--696ce";
export var textGrey100 = "OrgStats-module--text-grey-100--4f163";
export var textGrey150 = "OrgStats-module--text-grey-150--b4978";
export var textGrey200 = "OrgStats-module--text-grey-200--4f556";
export var textGrey300 = "OrgStats-module--text-grey-300--464b8";
export var textGrey400 = "OrgStats-module--text-grey-400--d3ad5";
export var textGrey500 = "OrgStats-module--text-grey-500--4bdab";
export var textGrey600 = "OrgStats-module--text-grey-600--c7268";
export var textGrey700 = "OrgStats-module--text-grey-700--fdf15";
export var textGrey800 = "OrgStats-module--text-grey-800--d9c16";
export var textGrey900 = "OrgStats-module--text-grey-900--d8bcf";