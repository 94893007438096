import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import { Row, Col } from "react-bootstrap"

import {
  Section,
  Container,
  SectionHeading,
  Link,
  TextBlock,
} from "../../UI/Common"

import {
  textAreaWrapperCls,
  textAreaCls,
  imageWrapperCls,
  statsPositionCls,
} from "./TextImageAndStats.module.scss"
import OrgStats from "./OrgStats"

const TextImageAndStats = ({
  googleReviewData,
  image,
  stats,
  sectionHeading,
  text,
  links,
  config,
}) => {
  const {
    googlePlacesPlace: { rating },
  } = googleReviewData

  const { padding } = config || {}

  return (
    <Section className="position-relative" padding={padding}>
      <Container fluid="lg">
        <Row>
          <Col xs={12} md={6} className="position-static">
            <div className={textAreaWrapperCls}>
              <div className={textAreaCls}>
                <SectionHeading text={sectionHeading} />
                <TextBlock text={text} mode="muted" />
                {React.Children.toArray(links.map(item => {
                  const { link } = item
                  return (
                    <Link
                      className="d-block mb-3"
                      link={link?.url}
                      title={link?.title}
                      target={link?.target}
                    />
                  )
                }))}
                <div className={statsPositionCls}>
                  <OrgStats stats={stats} rating={rating} />
                </div>
              </div>
            </div>
          </Col>
          <Col xs={12} md={6} className="position-static">
            <div className={imageWrapperCls}>
              {image && (
                <GatsbyImage
                  className="h-100"
                  alt={image.altText}
                  image={getImage(image.localFile)}
                />
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </Section>
  )
}

export default TextImageAndStats
