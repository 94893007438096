import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import { Row, Col } from "react-bootstrap"
import { v4 as uuidv4 } from "uuid"

import {
  statsWrapperCls,
  statBoxCls,
  ratingBoxCls,
  statCls,
  statLableCls,
  starsCls,
} from "./OrgStats.module.scss"

const OrgStats = ({ stats, rating }) => {
  return (
    <div className={statsWrapperCls}>
      <Row>
        {stats.map(item => (
          <Col  xs={12} md={4} key={uuidv4()}>
            <div className={statBoxCls}>
              <div className={statCls}>{item.stat}</div>
              <div className={statLableCls}>{item.label}</div>
            </div>
          </Col>
        ))}
        <Col xs={12} md={4} >
          <div className={ratingBoxCls}>
            <div>
              <span className={statCls}>{rating}</span>
              <span
                className={starsCls}
                aria-label={`Rated ${rating} out of 5.`}></span>
            </div>
            <div className={statLableCls}>Customer reviews</div>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default OrgStats
