// extracted by mini-css-extract-plugin
export var bgGrey100 = "TextImageAndStats-module--bg-grey-100--50d7e";
export var bgGrey150 = "TextImageAndStats-module--bg-grey-150--43361";
export var bgGrey200 = "TextImageAndStats-module--bg-grey-200--0830d";
export var bgGrey300 = "TextImageAndStats-module--bg-grey-300--953ff";
export var bgGrey400 = "TextImageAndStats-module--bg-grey-400--15b14";
export var bgGrey500 = "TextImageAndStats-module--bg-grey-500--3b075";
export var bgGrey600 = "TextImageAndStats-module--bg-grey-600--a7a5e";
export var bgGrey700 = "TextImageAndStats-module--bg-grey-700--32c61";
export var bgGrey800 = "TextImageAndStats-module--bg-grey-800--77c0f";
export var bgGrey900 = "TextImageAndStats-module--bg-grey-900--8ce0b";
export var imageWrapperCls = "TextImageAndStats-module--imageWrapperCls--8d398";
export var statsPositionCls = "TextImageAndStats-module--statsPositionCls--6ece1";
export var textAreaCls = "TextImageAndStats-module--textAreaCls--3d97a";
export var textAreaWrapperCls = "TextImageAndStats-module--textAreaWrapperCls--c29e7";
export var textGrey100 = "TextImageAndStats-module--text-grey-100--d06a7";
export var textGrey150 = "TextImageAndStats-module--text-grey-150--45b59";
export var textGrey200 = "TextImageAndStats-module--text-grey-200--73532";
export var textGrey300 = "TextImageAndStats-module--text-grey-300--2cbf9";
export var textGrey400 = "TextImageAndStats-module--text-grey-400--74056";
export var textGrey500 = "TextImageAndStats-module--text-grey-500--a96f8";
export var textGrey600 = "TextImageAndStats-module--text-grey-600--c904b";
export var textGrey700 = "TextImageAndStats-module--text-grey-700--494c7";
export var textGrey800 = "TextImageAndStats-module--text-grey-800--7b0c1";
export var textGrey900 = "TextImageAndStats-module--text-grey-900--5e642";